export const no_pics = {
    // 'addon-before-after': 'No pics available',
    'addon-before-after': '',
    // 'addon-videos': 'No pics available',
    'addon-videos': '',
    // 'product-addons': 'No pics available',
    'product-addons': '',
    // 'treatment-before-after': 'No pics available',
    'treatment-before-after': '',
};

export const sensible = {
    'addon-before-after': 'Due to the sensitive nature of those photos we can not display them.',
    'addon-videos': 'Due to the sensitive nature of those photos we can not display them.',
    'product-addons': 'Due to the sensitive nature of those photos we can not display them.',
    'treatment-before-after': 'Due to the sensitive nature of those photos we can not display them.',
};